import {platformDef} from '../common/global';


var scrollCase;


export const mainOther = () => {

  if (!$('#page-home').length) {
    const checkPlDef = platformDef();
    if(checkPlDef !== 'mobile-platform') {


      animationHeader();
      animationWelcomeScreen();


      if(!$('.bvi-body').length) {
        scrollCase = Scrollbar.init(document.getElementById('global-case'), {
          speed: 1,
          renderByPixels: true,
          alwaysShowTracks: true,
          overscrollEffect: 'glow',
          overscrollEffectColor: '#7FA2D5'
        });

        animationScroll(scrollCase);
      }
    }
  }
  if ($('.faq-wrap').length) {
    accardion();
  }

  if ($(document.body).hasClass('about-page')) {
    initOutTeamSwip();
  }

    $('.request-call, .header-pci-request-call').on('click', function () {
        $('.modal-request-call').addClass('show-modal');
    });

    $('.packages-wrap .select-plan').on('click', function () {
        $('.modal-type-akcii').addClass('show-modal');
    });

    $('.close-modal, .wrap-modal').on('click', function (e) {
      if($(e.target).hasClass('wrap-modal') || $(e.target).hasClass('close-modal')){
        $(this).closest('.wrap-modal').removeClass('show-modal');
        $('.wrap-modal').removeClass('show-modal');
      }
    });


  if ($(document.body).hasClass('statiya-page')) {
    var $firstP = $('.wrap-statiya-narrow-txt >p:first-child');

    $firstP.attr('data-first-letter', $firstP.text().trim()[0] );
    $($firstP).text( $($firstP).text().trim().slice(1) );
  }
};



const animationHeader = () => {

    const header = $('#header');

    header.attr('data-animation-elem', true);

    setTimeout(() => {header.addClass('checked');}, 1200);

};

const animationWelcomeScreen = () => {

    const sectionHeader = $('.header-pc-inner');
    const sectionGradients = $('.ph-welcome-ground');

    const headerWelcomeList = $('.header-pci-list >li');
    const headerWelcomeRequest = $('.header-pci-request-call:not(.select-plan)');

    // ***

    const initGradients = sectionGradients.find('[data-animation-elem]').attr('data-animation-elem', true);
    const initHeader =  sectionHeader.find('[data-animation-elem]').attr('data-animation-elem', true);

    for(let i = 0; i < headerWelcomeList.length; i++) {
        headerWelcomeList.eq(i).css('transition-delay', `${i/8 + 0.1}s`);

        if(i < headerWelcomeList.length - 1) {
            headerWelcomeRequest.eq(i).css('transition-delay', `${i/8 + 0.2}s`);
        }
    }

    setTimeout(() => {
        initGradients.addClass('checked');
    }, 300);

    setTimeout(() => {
        initHeader.addClass('checked');
    }, 900);

    // ***

    const sectionSlider = $('.ph-wc-left');
    const initSlider =  sectionSlider.find('[data-animation-elem]').attr('data-animation-elem', true);

    setTimeout(() => {
        initSlider.addClass('checked');
    }, 1200);

    setTimeout(() => {
        initSlider.addClass('init-slider');
    }, 1700);

    // ***

    const initSliderImages = $('.ph-wc-imgs-mini');

    initSliderImages.attr('data-animation-elem', true);

    setTimeout(() => {
        initSliderImages.addClass('checked');
    }, 1800);

    // ***

    const sectionIcons = $('.ph-welcome-icons');
    const initIcons =  sectionIcons.find('[data-animation-elem]').attr('data-animation-elem', true);

    for(let i = 0; i < initIcons.length; i++) {
        initIcons.eq(i).css('transition-delay', `${i/8 + 0.15}s`);
    }

    setTimeout(() => {
        sectionIcons.addClass('checked');
    }, 2200);

    // ***

    const sectionScrollButton = $('.welcome-scll-down');
    sectionScrollButton.attr('data-animation-elem', true);

    setTimeout(() => {
        sectionScrollButton.addClass('checked');
    }, 2800);

    setTimeout(() => {
        initIcons.addClass('loop-frame');
    }, 3500);

};



const animationScroll = (scrollCase) => {

  const requestScreen = () => {

    const requestFormFields = $('.ph-rc-cf-one');

    for(let i = 0; i < requestFormFields.length; i++) {
      requestFormFields.eq(i).css('transition-delay', `${i/6 + 0.5}s`);
    }

  };

  const advantagesScreen = () => {

    const advantagesOneBlock = $('.ph-ac-one');

    for(let i = 0; i < advantagesOneBlock.length; i++) {
      advantagesOneBlock.eq(i).css('transition-delay', `${(i%3)/8 + 0.5}s`);
    }

  };

  const retainerScreen = () => {

    const retainerFormFields = $('.ph-crc-form-one-field');

    for(let i = 0; i < retainerFormFields.length; i++) {
      retainerFormFields.eq(i).css('transition-delay', `${i/6 + 0.5}s`);
    }

  };

  const aboutScreen = () => {

    const aboutTextBlocks = $('.ph-about-bctl-one');

    for(let i = 0; i < aboutTextBlocks.length; i++) {
      aboutTextBlocks.eq(i).css('transition-delay', `${i/7 + 0.6}s`);
    }

  };

  const logosScreen = () => {

    const oneLogoElement = $('.ph-logo-list-one');

    for(let i = 0; i < oneLogoElement.length; i++) {
      oneLogoElement.eq(i).css('transition-delay', `${i/9 + 0.6}s`);
    }

  };

  const articlesScreen = (scrollCase) => {

    const oneArticleElement = $('.ph-article-cl-one');

    for(let i = 0; i < oneArticleElement.length; i++) {
      oneArticleElement.eq(i).css('transition-delay', `${i/8 + 0.6}s`);
    }

  };

  const faqOne = () => {

      const faqColl = $('.faq-coll');
      const faqOne = faqColl.find('.faq-one');

      var j = 0;
      for(let i = 0; i < faqOne.length; i++) {
          console.log(':: ', i)
          faqColl.eq(0).children().eq(i).css('transition-delay', `${j/18 + 0.1}s`);
          j++;
          faqColl.eq(1).children().eq(i).css('transition-delay', `${j/18 + 0.1}s`);
          j++;
      }
  };

  const akciiOneTransDel = () => {

        const akciiOneBlock = $('.akcii-one');

        for(let i = 0; i < akciiOneBlock.length; i++) {
            akciiOneBlock.eq(i).css('transition-delay', `${(i%3)/9 + 0.2}s`);
        }

  };

  const packOneTransDel = () => {

        const packOneBlock = $('.akciya-type');

        for(let i = 0; i < packOneBlock.length; i++) {
            packOneBlock.eq(i).css('transition-delay', `${(i%3)/9 + 0.2}s`);
        }

  };

  const statiiOneTransDel = () => {

        const akciiOneBlock = $('.statii-one');

        for(let i = 0; i < akciiOneBlock.length; i++) {
            akciiOneBlock.eq(i).css('transition-delay', `${(i%4)/18 + 0.2}s`);
        }

  };

  const aboutSlide = (scrollCase) => {
      const akciiOneBlock = $('.swpr-our-team .swiper-slide.animation-element');

      let j=$('.swpr-our-team .swiper-slide.swiper-slide-prev').index();
      for(let i = 0; i < 4; i++) {
          akciiOneBlock.eq(j).css('transition-delay', `${i/8 + 0.5}s`);
          j++;
      }
  }



  requestScreen();
  advantagesScreen();
  retainerScreen();
  aboutScreen();
  logosScreen();
  articlesScreen();




  faqOne();
  akciiOneTransDel();
  packOneTransDel();
  statiiOneTransDel();
  aboutSlide(scrollCase);


  setTimeout(function () {
      showElements(scrollCase);
  }, 100)

  scrollCase.addListener(() => {
      showElements(scrollCase);
  });
};



window.showElements = (scrollCase) => {

    const animationElements =  document.getElementsByClassName('animation-element');

    for(const animationElement of animationElements) {

        if(scrollCase.isVisible(animationElement)) {

            if(animationElement.classList.contains('checked') !== true) {

                if(animationElement.classList.contains('checked') !== true) {
                    setTimeout(() => {animationElement.className += ' loop-frame';}, 1600);
                }

                animationElement.className += ' checked';
            }
        }

    }
}



// const animationAction = () => {
//
//   if(checkPlDef !== 'mobile-platform') {
//
//
//     const scrollCase = Scrollbar.init(document.getElementById('global-case'), {
//       speed: 1,
//       renderByPixels: true,
//       alwaysShowTracks: true,
//       overscrollEffect: 'glow',
//       overscrollEffectColor: '#7FA2D5'
//     });
//
//     animationActionScroll(scrollCase);
//
//     animationScroll(scrollCase);
//
//   }
//
// };

const animationActionScroll = (scrollCase) => {


};


$(window).load(windowSize);
$(window).resize(windowSize);
$(window).on('load resize', windowSize);

function windowSize() {
    if ($(window).width() <= '900') {
        $('#package #ph-sc-app-no').attr('id', 'ph-sc-app');
        $('#package #ph-sc-app .swiper-wrapper-no').attr('class', 'swiper-wrapper');

        if ($('#ph-sc-app').length) {
            serviceSlider();
        }
    } else {
        $('#package #ph-sc-app').attr('id', 'ph-sc-app-no');
        $('#package #ph-sc-app-no .swiper-wrapper').attr('class', 'swiper-wrapper-no');
    }
};

const serviceSlider = () => {
    new Swiper('#ph-sc-app', {
        speed: 400,
        grabCursor: true,
        spaceBetween: 0,
        slidesPerView: 2,
        pagination: {
            el: '.ph-sc-app-pg',
            clickable: true
        },
        breakpoints: {
            800: {
                slidesPerView: 1
            }
        }
    });
};

$('#other-services').on('click', function () {
    $('.other-services').toggleClass('none');
    $(this).toggleClass('open');
});


function animationShowPage(){

};



const initOutTeamSwip = () => {
  new Swiper('#swpr-our-team', {
    speed: 400,
    grabCursor: true,
    // initialSlide: 1,
    slidesPerView: 3.5,
    centeredSlides: true,
    loop: true,
    loopAdditionalSlides: 2,
    pagination: {
      el: '.ph-sc-app-pg',
      clickable: true
    },
    breakpoints: {
      1200: {
        slidesPerView: 2.5
      },
      960: {
        slidesPerView: 1.5
      },
      425: {
        slidesPerView: 1
      }
    }
  });

};


const accardion = () => {

  console.log(123)

  $('body').on('click', '.faq-one dt', function () {
    const prnt = $(this).parent();
    if (!prnt.hasClass('active')) {
      $('.faq-one').removeClass('active');
      prnt.addClass('active');
      $('.faq-one:not(.active)').each(function (i, faq) {
        $(faq).find('.inn').slideUp(400);
      });

      prnt.find('.inn').slideDown(400);
    }
    else{
      prnt.removeClass('active');
      prnt.find('.inn').slideUp(400);
    }
  });

};