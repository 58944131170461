// → IMPORT COMMON MODULES

import {loaderPage} from './modules/common/global';
import {platformDef} from './modules/common/global';
import {customSelect} from './modules/common/global';
import {switchLanguages} from './modules/common/global';
import {mobilePlatform} from './modules/common/global';

// → IMPORT PAGE MODULES

import {mainHome} from './modules/pages/home';
import {mainOther} from './modules/pages/other';

// → CREATE MAIN APPLICATION

const application = () => {

    platformDef();


    customSelect();
    switchLanguages();
    mobilePlatform();

    loaderPage(mainHome);

    mainOther();

    // initBvi();



    $(document).on('click', '.arrow-top-nav', function () {
      var parrentLi = $(this).closest('li');
      var innerMenu = parrentLi.find('ul.inner-menu');


      if(!parrentLi.hasClass('open')){
        innerMenu.css('height', innerMenu[0].scrollHeight+'px')
      }
      else{
        innerMenu.css('height', 0);
      }


      $(this).closest('li').toggleClass('open');
    });

    $(document).on('click', '.bvi-panel-open', function () {
      console.log('click .bvi-panel-open')
      $('html').addClass('mobile-platform');
      Scrollbar.destroyAll();
    })
};

// → LAUNCHING MAIN APPLICATION

document.addEventListener('DOMContentLoaded', application());


jQuery(document).ready(function($) {

});