'use strict';

import 'babel-polyfill';
import {platformDef} from '../common/global';
import {isValidName} from '../common/instruments';
import {isValidMobile} from '../common/instruments';
import {isValidEmailAddress} from '../common/instruments';

export const mainHome = () => {
    animationHome();


        $('.bvi-panel-open').bvi('Init', {"BviPanel" : "1", "BviPanelBg" : "white", "BviPanelFontSize" : "12", "BviPanelLetterSpacing" : "normal", "BviPanelLineHeight" : "normal", "BviPanelImg" : "1", "BviPanelImgXY" : "1", "BviPanelReload" : "1", "BviCloseClassAndId" : ".hide-screen-fixed", "BviFixPanel" : "0", "BviPlay" : "0"});
        console.log(11);
        if($('.bvi-body').length){
            $('html').addClass('mobile-platform');

        }


    if($('#page-home').length){
      welcomeSlider();
      serviceSlider();
      reviewsSlider();
      calcRetainer();
      getConsultation();
      receiveNewsletter();


      if(!$('.bvi-body').length){
        animationHome();
      }
    }

};

const welcomeSlider = () => {

    const welcomeImageMini = $('.ph-wc-im-one');



    const welcomeSwiper = new Swiper('#ph-wc-app', {
        speed: 400,
        spaceBetween: 0,
        simulateTouch: false,
        autoplay: {delay: 5000},
        pagination: {
            el: '.ph-wc-app-pg',
            clickable: true
        },
        breakpoints: {
            1200: {
                autoHeight: true,
                simulateTouch: true
            }
        },
        on: {
            init: function() {
                setTimeout(() => {
                    const swiperBullets = $('.ph-wc-app-pg > span');

                    for(let i = 0; i < swiperBullets.length; i++) {
                        swiperBullets.eq(i).css('transition-delay', (i/8 + 0.8) + 's');
                    }
                }, 0);
            }
        }
    });

    window.sw = welcomeSwiper;

    const checkPlDef = platformDef();

    if(checkPlDef !== 'mobile-platform') {
        welcomeSwiper.params.effect = 'fade';
        welcomeSwiper.update();
        setTimeout(function () { welcomeSwiper.update(); }, 100)
    }

    const body = $('body');
    const allSlides = $('#ph-wc-app .swiper-slide');
    const autoplayController = '.ph-wc-app-controller';

    welcomeSwiper.on('slideChangeTransitionStart', function() {

        welcomeImageMini.removeClass('active');
        allSlides.find('[data-animation-elem]').removeClass('checked');

    });

    welcomeSwiper.on('slideChangeTransitionEnd', function() {

        setTimeout(() => {
            allSlides.find('[data-animation-elem]').addClass('checked');
            welcomeImageMini.eq(welcomeSwiper.activeIndex).addClass('active');
        }, 400);

    });

    body.on('click', autoplayController, function(){

        const ths = $(this);

        if(!ths.hasClass('active')) {

            ths.addClass('active');

            welcomeSwiper.autoplay.start();
            welcomeSwiper.update();

        } else {

            ths.removeClass('active');

            welcomeSwiper.autoplay.stop();
            welcomeSwiper.update();
        }

    });

};

const serviceSlider = () => {

    new Swiper('#ph-sc-app', {
        speed: 400,
        grabCursor: true,
        spaceBetween: 0,
        slidesPerView: 2,
        pagination: {
            el: '.ph-sc-app-pg',
            clickable: true
        },
        breakpoints: {
            1200: {
                slidesPerView: 1
            }
        }
    });

};

const reviewsSlider = () => {

    const comentSwiper = new Swiper('#ph-rv-app', {
        speed: 400,
        spaceBetween: 0,
        slidesPerView: '1',
        autoHeight: true,
        pagination: {
            el: '.ph-rw-app-pg',
            clickable: true
        },
        navigation: {
            nextEl: '.ph-rv-app-arrow.right',
            prevEl: '.ph-rv-app-arrow.left'
        }
    });

    setTimeout(function () {
        comentSwiper.update();
    });

};

const calcRetainer = () => {

    const body = $('body');
    const nameButtonReceive = '#ph-crc-btn-submit';

    const countComps = $('#count-comps');
    const countServers = $('#count-servers');
    const locationAts = $('#location');
    const selectRetainer = $('#ats');
    const atsRetainer = selectRetainer.siblings('.common-select-field');
    const oneElemAtsRetainer = atsRetainer.find('.list li');

    countComps.mask('#', {reverse: true});
    countServers.mask('#', {reverse: true});

    body.on('click', nameButtonReceive, function(){

        let boolSendForm = true;

        const dataCountComps = countComps.val().trim();
        const dataCountServers = countServers.val().trim();
        const dataLocationAts = locationAts.val().trim();

        const showReceiveForm = $('#show-message-retainer');
        const hiddenReceiveForm = $('#hidden-message-retainer');

        const checkFieldsForm = (fieldForm) => {

            boolSendForm = false;
            fieldForm.addClass('error-field');

            setTimeout(() => {
                fieldForm.removeClass('error-field');
            }, 600);

        };

        if(dataCountComps == '') {
            checkFieldsForm(countComps);
        }

        if(dataCountServers == '') {
            checkFieldsForm(countServers);
        }

        if(dataLocationAts == '') {
            checkFieldsForm(locationAts);
        }

        if(oneElemAtsRetainer.eq(0).hasClass('selected')) {
            checkFieldsForm(atsRetainer);
        }

        if(boolSendForm === true) {

            $(nameButtonReceive).prop('disabled', true);

            showReceiveForm.addClass('sending');
            hiddenReceiveForm.addClass('sending');

            setTimeout(() => {

                countComps.val('');
                countServers.val('');
                locationAts.val('');

                oneElemAtsRetainer.removeClass('selected');
                oneElemAtsRetainer.eq(0).addClass('selected');
                atsRetainer.find('.current').text('');

            }, 600);

            setTimeout(() => {
                showReceiveForm.removeClass('sending');
                hiddenReceiveForm.removeClass('sending');
            }, 2000);

            setTimeout(() => {
                $(nameButtonReceive).prop('disabled', false);
            }, 2600);

        }

    });

};

const getConsultation = () => {

    const body = $('body');
    const nameButtonReceive = '#ph-rc-btn-submit';

    const nameReceive = $('#name-request');
    const phoneReceive = $('#phone-request');
    const emailReceive = $('#email-request');
    const selectReceive = $('#type-message-request');
    const typeMessageReceive = selectReceive.siblings('.common-select-field');
    const oneElemMessageReceive = typeMessageReceive.find('.list li');

    phoneReceive.mask('#');

    body.on('click', nameButtonReceive, function(){

        let boolSendForm = true;

        const dataNameReceive = nameReceive.val().trim();
        const dataPhoneReceive = phoneReceive.val().trim();
        const dataEmailReceive = emailReceive.val().trim();

        const showReceiveForm = $('#show-message-request');
        const hiddenReceiveForm = $('#hidden-message-request');

        const checkFieldsForm = (fieldForm) => {

            boolSendForm = false;
            fieldForm.addClass('error-field');

            setTimeout(() => {
                fieldForm.removeClass('error-field');
            }, 600);

        };

        if(!isValidName(dataNameReceive)) {
            checkFieldsForm(nameReceive);
        }

        if(!isValidMobile(dataPhoneReceive)) {
            checkFieldsForm(phoneReceive);
        }

        if(!isValidEmailAddress(dataEmailReceive)) {
            checkFieldsForm(emailReceive);
        }

        if(oneElemMessageReceive.eq(0).hasClass('selected')) {
            checkFieldsForm(typeMessageReceive);
        }

        if(boolSendForm === true) {

            $(nameButtonReceive).prop('disabled', true);

            showReceiveForm.addClass('sending');
            hiddenReceiveForm.addClass('sending');

            setTimeout(() => {

                nameReceive.val('');
                phoneReceive.val('');
                emailReceive.val('');

                oneElemMessageReceive.removeClass('selected');
                oneElemMessageReceive.eq(0).addClass('selected');
                typeMessageReceive.find('.current').text('');

            }, 600);

            setTimeout(() => {
                showReceiveForm.removeClass('sending');
                hiddenReceiveForm.removeClass('sending');
            }, 2000);

            setTimeout(() => {
                $(nameButtonReceive).prop('disabled', false);
            }, 2600);

        }

    });

};

const receiveNewsletter = () => {

    const body = $('body');
    const nameButtonReceive = '#ph-fm-btn-submit';

    const nameReceive = $('#name-form');
    const phoneReceive = $('#phone-form');
    const emailReceive = $('#email-form');
    const selectReceive = $('#type-message-form');
    const typeMessageReceive = selectReceive.siblings('.common-select-field');
    const oneElemMessageReceive = typeMessageReceive.find('.list li');

    phoneReceive.mask('#');

    body.on('click', nameButtonReceive, function(){

        let boolSendForm = true;

        const dataNameReceive = nameReceive.val().trim();
        const dataPhoneReceive = phoneReceive.val().trim();
        const dataEmailReceive = emailReceive.val().trim();

        const showReceiveForm = $('#show-message-form');
        const hiddenReceiveForm = $('#hidden-message-form');

        const checkFieldsForm = (fieldForm) => {

            boolSendForm = false;
            fieldForm.addClass('error-field');

            setTimeout(() => {
                fieldForm.removeClass('error-field');
            }, 600);

        };
        
        if(!isValidName(dataNameReceive)) {
            checkFieldsForm(nameReceive);
        }

        if(!isValidMobile(dataPhoneReceive)) {
            checkFieldsForm(phoneReceive);
        }

        if(!isValidEmailAddress(dataEmailReceive)) {
            checkFieldsForm(emailReceive);
        }

        if(oneElemMessageReceive.eq(0).hasClass('selected')) {
            checkFieldsForm(typeMessageReceive);
        }

        if(boolSendForm === true) {

            $(nameButtonReceive).prop('disabled', true);

            showReceiveForm.addClass('sending');
            hiddenReceiveForm.addClass('sending');

            setTimeout(() => {

                nameReceive.val('');
                phoneReceive.val('');
                emailReceive.val('');

                oneElemMessageReceive.removeClass('selected');
                oneElemMessageReceive.eq(0).addClass('selected');
                typeMessageReceive.find('.current').text('');

            }, 600);

            setTimeout(() => {
                showReceiveForm.removeClass('sending');
                hiddenReceiveForm.removeClass('sending');
            }, 2000);

            setTimeout(() => {
                $(nameButtonReceive).prop('disabled', false);
            }, 2600);

        }
    });

};

const animationScroll = (scrollCase) => {

    const requestScreen = () => {

        const requestFormFields = $('.ph-rc-cf-one');

        for(let i = 0; i < requestFormFields.length; i++) {
            requestFormFields.eq(i).css('transition-delay', `${i/6 + 0.5}s`);
        }

    };

    const advantagesScreen = () => {

        const advantagesOneBlock = $('.ph-ac-one');

        for(let i = 0; i < advantagesOneBlock.length; i++) {
            advantagesOneBlock.eq(i).css('transition-delay', `${(i%3)/8 + 0.5}s`);
        }

    };

    const retainerScreen = () => {

        const retainerFormFields = $('.ph-crc-form-one-field');

        for(let i = 0; i < retainerFormFields.length; i++) {
            retainerFormFields.eq(i).css('transition-delay', `${i/6 + 0.5}s`);
        }

    };

    const aboutScreen = () => {

        const aboutTextBlocks = $('.ph-about-bctl-one');

        for(let i = 0; i < aboutTextBlocks.length; i++) {
            aboutTextBlocks.eq(i).css('transition-delay', `${i/7 + 0.6}s`);
        }

    };

    const logosScreen = () => {

        const oneLogoElement = $('.ph-logo-list-one');

        for(let i = 0; i < oneLogoElement.length; i++) {
            oneLogoElement.eq(i).css('transition-delay', `${i/9 + 0.6}s`);
        }

    };

    const articlesScreen = () => {

        const oneArticleElement = $('.ph-article-cl-one');

        for(let i = 0; i < oneArticleElement.length; i++) {
            oneArticleElement.eq(i).css('transition-delay', `${i/8 + 0.6}s`);
        }

    };


    requestScreen();
    advantagesScreen();
    retainerScreen();
    aboutScreen();
    logosScreen();
    articlesScreen();



    const animationElements =  document.getElementsByClassName('animation-element');

    scrollCase.addListener(() => {

        for(const animationElement of animationElements) {

            if(scrollCase.isVisible(animationElement)) {

                if(animationElement.classList.contains('checked') !== true) {

                    if(animationElement.classList.contains('checked') !== true) {
                        setTimeout(() => {animationElement.className += ' loop-frame';}, 1600);
                    }

                    animationElement.className += ' checked';
                }
            }

        }

    });

};

const animationHome = () => {

    const body = $('body');
    const checkPlDef = platformDef();
    const welcomeScrollDown = '.welcome-scll-down';
    const welcomeScrollSection = $('.ph-section.request');
    if(!welcomeScrollSection.length) return false;
    const offsetWelcomeSS = welcomeScrollSection.offset().top;

    if(checkPlDef !== 'mobile-platform') {

        animationHeader();
        animationWelcomeScreen();

        const scrollCase = Scrollbar.init(document.getElementById('global-case'), {
            speed: 1,
            renderByPixels: true,
            alwaysShowTracks: true,
            overscrollEffect: 'glow',
            overscrollEffectColor: '#7FA2D5'
        });

        animationScroll(scrollCase);

        body.on('click', welcomeScrollDown, function(){
            scrollCase.scrollTo(0, offsetWelcomeSS, 800);
        });

    } else {

        body.on('click', welcomeScrollDown, function(){
            const headerHeight = $('#header').height();
            const welcomeHeight = $('.ph-section.welcome').height();
            $('html, body').animate({scrollTop: (headerHeight + welcomeHeight)}, 500);
        });

    }

};

const animationHeader = () => {

    const header = $('#header');

    header.attr('data-animation-elem', true);

    setTimeout(() => {header.addClass('checked');}, 1200);

};

const animationWelcomeScreen = () => {

    const sectionHeader = $('.header-pc-inner');
    const sectionGradients = $('.ph-welcome-ground');

    const headerWelcomeList = $('.header-pci-list >li');
    const headerWelcomeRequest = $('.header-pci-request-call');

    // ***

    const initGradients = sectionGradients.find('[data-animation-elem]').attr('data-animation-elem', true);
    const initHeader =  sectionHeader.find('[data-animation-elem]').attr('data-animation-elem', true);

    for(let i = 0; i < headerWelcomeList.length; i++) {
        headerWelcomeList.eq(i).css('transition-delay', `${i/8 + 0.1}s`);

        if(i < headerWelcomeList.length - 1) {
            headerWelcomeRequest.eq(i).css('transition-delay', `${i/8 + 0.2}s`);
        }
    }

    setTimeout(() => {
        initGradients.addClass('checked');
    }, 300);

    setTimeout(() => {
        initHeader.addClass('checked');
    }, 900);

    // ***

    const sectionSlider = $('.ph-wc-left');
    const initSlider =  sectionSlider.find('[data-animation-elem]').attr('data-animation-elem', true);

    setTimeout(() => {
        initSlider.addClass('checked');
    }, 1200);

    setTimeout(() => {
        initSlider.addClass('init-slider');
    }, 1700);

    // ***

    const initSliderImages = $('.ph-wc-imgs-mini');

    initSliderImages.attr('data-animation-elem', true);

    setTimeout(() => {
        initSliderImages.addClass('checked');
    }, 1800);

    // ***

    const sectionIcons = $('.ph-welcome-icons');
    const initIcons =  sectionIcons.find('[data-animation-elem]').attr('data-animation-elem', true);

    for(let i = 0; i < initIcons.length; i++) {
        initIcons.eq(i).css('transition-delay', `${i/8 + 0.15}s`);
    }

    setTimeout(() => {
        sectionIcons.addClass('checked');
    }, 2200);

    // ***

    const sectionScrollButton = $('.welcome-scll-down');
    sectionScrollButton.attr('data-animation-elem', true);

    setTimeout(() => {
        sectionScrollButton.addClass('checked');
    }, 2800);

    setTimeout(() => {
        initIcons.addClass('loop-frame');
    }, 3500);

};